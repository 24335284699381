var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","fixed":""}},[_c('v-toolbar-title',[_c('router-link',{staticClass:"-intro-x",attrs:{"to":{ name: 'home' }}},[_c('Logo')],1)],1),_c('Breadcrumb'),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":"","nudge-bottom":"34"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Decks")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'deck-list' }}},[_c('v-list-item-title',[_vm._v("Browse")])],1),_c('v-list-item',{attrs:{"to":{name: 'collection-home'}}},[_c('v-list-item-title',[_vm._v("Collections")])],1)],1)],1),_c('v-menu',{attrs:{"left":"","bottom":"","nudge-bottom":"34"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Industry")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'company-list' }}},[_c('v-list-item-title',[_vm._v("Companies")])],1),_c('v-list-item',{attrs:{"to":{ name: 'artist-list' }}},[_c('v-list-item-title',[_vm._v("Artists")])],1),_c('v-list-item',{attrs:{"to":{ name: 'pro-list' }}},[_c('v-list-item-title',[_vm._v("Pro Skateboarders")])],1)],1)],1),_c('v-menu',{attrs:{"left":"","bottom":"","nudge-bottom":"34"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("About")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'about' }}},[_c('v-list-item-title',[_vm._v("About SkateLurker")])],1),_c('v-list-item',{attrs:{"to":{ name: 'blog-list' }}},[_c('v-list-item-title',[_vm._v("Blog")])],1),_c('v-list-item',{attrs:{"to":{ name: 'contact' }}},[_c('v-list-item-title',[_vm._v("Contact Us")])],1)],1)],1),(_vm.user)?_c('v-menu',{attrs:{"left":"","bottom":"","nudge-bottom":"34"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.user.displayName))])]}}],null,false,396800940)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'about' }}},[_c('v-list-item-title',[_vm._v("Profile")])],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"text":""},on:{"click":function($event){_vm.$refs.mobileMenu.visible = !_vm.$refs.mobileMenu.visible}}},[_c('fa-icon',{attrs:{"icon":['fal','bars']}})],1),_c('MobileMenu',{ref:"mobileMenu"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }